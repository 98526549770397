import React, { useState, useEffect } from 'react'
import { enUS } from 'date-fns/locale'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

const BookingModule = () => {

  const [numBeds, setBeds] = useState('Any');
  const [numAdults, setAdults] = useState('1');
  const [numChildren, setChildren] = useState('0');
  const [numInfants, setInfants] = useState('0');

  // const initialStartDate = new Date( new Date( new Date().setDate( new Date().getDate() + 0 ) ).toDateString() );
  // const initialEndDate = new Date( new Date( new Date().setDate( new Date().getDate() + 1 ) ).toDateString() );

  // console.log('Debug Start Date:', initialStartDate);
  // console.log('Debug End Date:', initialEndDate);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [nightCountString, setNightCountString] = useState(getNightCount());

  const [roomsGuestsDropdownDisplay, setRoomsGuestsDropdownDisplay] = useState(false);

  function toggleRoomsGuestsDropdown() {
    if (roomsGuestsDropdownDisplay) {
      setRoomsGuestsDropdownDisplay(false);
    } else {
      setRoomsGuestsDropdownDisplay(true);
    }
  }

  function getNightCount() {
    var diffInDays = 0;
    if (endDate && startDate) {
      var diffInTime = endDate.getTime() - startDate.getTime();
      diffInDays = diffInTime / (1000 * 3600 * 24);
      diffInDays = Math.round(diffInDays);
    }
    return diffInDays > 0 ? (': ' + diffInDays + ' Night' + (diffInDays === 1 ? '' : 's')) : '';
  }

  function formatDateForWebResPro(date) {
    var formattedDate = null;
    if (date) {
      var mm = date.getMonth() + 1;
      var dd = date.getDate();
      formattedDate = [ date.getFullYear(), (mm>9 ? '' : '0') + mm, (dd>9 ? '' : '0') + dd ].join('');
    }
    return formattedDate;
  }

  useEffect(() => {
    setNightCountString(
      getNightCount()
    );
  }, [startDate, endDate]);

  return (
    <section className="booking-module-container container">
      <div className="col-0 col-medium-1-10"></div>
      <div className="booking-module col-100 col-medium-8-10">
        <form className="booking-module-form" target="_new" action="https://secure.webrez.com/hotel/1719/?" method="get">
          <input type="hidden" name="table" value="hotels" readOnly />
          <input type="hidden" name="hotel_id" value="1719" readOnly />
          <input type="hidden" name="listing_id" value="1719" readOnly />
          <input type="hidden" name="mode" value="command" readOnly />
          <input type="hidden" name="command" value="pleasewait" readOnly />
          <input type="hidden" name="nextcommand" value="roomsearch" readOnly />

          <div className="form-group col-100 col-small-1-2 col-medium-1-3">
            <label className="form-label">
              <span>Choose Dates</span>
              <span className="booking-date-count">{nightCountString}</span>
            </label>
            <div className="date-input-container full-width">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                minimumDate={new Date()}
                minimumLength={1}
                format='E, MMM d'
                locale={enUS}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className='date-range'>
                    <div className="datepicker-input-container">
                      <input
                        className={'input' + (focus === START_DATE ? ' -focused' : '')}
                        {...startDateInputProps}
                        placeholder='Start date'
                      />
                      <input type="hidden" name="date_from" id="date_from" value={formatDateForWebResPro(startDate)} />
                    </div>
                    <div className="date-range-arrow icon-arrow-right"></div>
                    <div className="datepicker-input-container">
                      <input
                        className={'input' + (focus === END_DATE ? ' -focused' : '')}
                        {...endDateInputProps}
                        placeholder='End date'
                      />
                      <input type="hidden" name="date_to" id="date_to" value={formatDateForWebResPro(endDate)} />
                    </div>
                  </div>
                )}
              </DateRangePicker>
            </div>
          </div>
          <div className="form-group col-100 col-small-1-2 col-medium-1-3 rooms-guests-container">
            <label className="form-label">Rooms &amp; Guests</label>
            <div id="roomsGuestsInput" className={'input-box ' + ( roomsGuestsDropdownDisplay ? '-focused' : '' )} onClick={toggleRoomsGuestsDropdown}>
              {numBeds + ' Beds: ' + numAdults + ' Adult' + ( numChildren !== '0' ? ', ' + numChildren + ' Child' : '' ) + '/Room'}
            </div>
            <div className={'rooms-guests-overlay ' + ( roomsGuestsDropdownDisplay ? 'show' : '' )} onClick={toggleRoomsGuestsDropdown}></div>
            <div className={'rooms-guests-dropdown-container ' + ( roomsGuestsDropdownDisplay ? 'show' : '' )}>
              <div className="rooms-guests-dropdown">
                <div className="form-group">
                  <label className="form-label">Number of Beds</label>
                  <select name="num_beds" id="num_beds" onChange={beds => setBeds(beds.target.value === '0' ? 'Any' : '2+')} className="form-select full-width">
                    <option value="0">No Preference</option>
                    <option value="2">2+ Beds</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Adults</label>
                  <select name="num_adults" id="num_adults" value={numAdults} onChange={adults => setAdults(adults.target.value)} className="form-select full-width">
                    <option value="1">1 Adult Per Room</option>
                    <option value="2">2 Adults Per Room</option>
                    <option value="3">3 Adults Per Room</option>
                    <option value="4">4 Adults Per Room</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Children</label>
                  <select name="num_children" id="num_children" onChange={children => setChildren(children.target.value)} className="form-select full-width">
                    <option value="0">0 Children Per Room</option>
                    <option value="1">1 Child Per Room</option>
                    <option value="2">2 Children Per Room</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">Infants</label>
                  <select name="num_babies" id="num_babies" className="form-select full-width">
                    <option value="0">0 Infants Per Room</option>
                    <option value="1">1 Infant Per Room</option>
                    <option value="2">2 Infants Per Room</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-100 col-medium-1-3 check-availability-button-container">
            <label className="form-label">&nbsp;</label>
            <input type="submit" className="search-button full-width button" value="Check Availability" />
          </div>
        </form>
      </div>
    </section>
  )
}

export default BookingModule
